<template>
  <div class="warpper">
    <div class="product">
      <ul>
        <li v-for="(item, index) in bioList">
          <a @click="goThere(item)">
            <img :src="item.servicePath + item.cover" />
            <div class="product-name">
              <p>{{ item.brief }}</p>
              <span>more</span>
            </div>
          </a>
        </li>
      </ul>
      <div class="clears"></div>
    </div>
    <div style="text-align: center" v-if="page.total > 9">
      <page-view :page="page" @pageChange="pageChange"></page-view>
    </div>
  </div>
</template>

<script>
import pageView from "@components/pageView";
export default {
  name: "",
  components: {
    pageView,
  },
  data() {
    return {
      bioList: [],
      //分页
      page: {
        pageNo: 1,
        pageSize: 9,
        total: 0,
      },
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: this.page.pageSize,
          pageNo: this.page.pageNo,
        }).then((res) => {
          this.bioList = res.list;
          this.page.total = +res.total;
        });
      }
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    goThere(item) {
      this.$router.push({
        name: "newsInfo",
        query: {
          id: item.id,
          key: this.$route.name,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>