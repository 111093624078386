<template>
  <div>
    <group-banner></group-banner>
  </div>
</template>

<script>
import groupBanner from "@components/groupBanner";
export default {
  name: "",
  components: {
    groupBanner,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>